.authorArchiveStoriesTitle {
	--font-family: var(--typography-typeface-primary-family);
	--font-size: 2.25rem;
	--font-weight: 550;
	--line-height: 1;

	font-family: var(--font-family);
	font-size: var(--font-size);
	font-weight: var(--font-weight);
	line-height: var(--line-height);
	margin-bottom: var(--spacing-xl);
	padding-top: calc(var(--spacing-5xl) - var(--spacing-s));
	text-align: center;
}

.authorArchiveGrid {
	padding-top: 0;
}
